import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import "../styles/css/Reviews.css";
import { reviewsArr } from "../components/dataImports";
import { nanoid } from "nanoid";
import { useState } from "react";

export default function Reviews() {
	const [activeIndex, setActiveIndex] = useState(0);

	const updateIndex = (newindex) => {
		if (newindex < 0) {
			newindex = 0;
		} else if (newindex >= Math.floor(reviewsArr.length - 1)) {
			newindex = Math.floor(reviewsArr.length - 1);
		}
		setActiveIndex(newindex);
	};

	return (
		<>
			<div className="center reviews-container margin-medium-bottom padding-small-inline">
				<div
					className="left-arrow"
					onClick={() => {
						updateIndex(activeIndex - 1);
					}}
				>
					<FaAngleLeft
						size={"24"}
						fill={"#fff"}
					/>
				</div>
				<div className="reviews-wrapper">
					<div
						className="reviews center"
						style={{
							transform: `translate(-${activeIndex * 300}px)`,
						}}
					>
						{reviewsArr.map((review) => {
							const uniqueKey = nanoid();
							return (
								<>
									<div
										key={uniqueKey}
										className="review-item-div center flex-column padding-small-inline"
									>
										<p className="center">
											{review["content"]}
										</p>
										<p className="reviewer font-medium font-color-primary">
											- {review["name"]}
										</p>
									</div>
								</>
							);
						})}
					</div>
				</div>
				<div
					className="right-arrow"
					onClick={() => {
						updateIndex(activeIndex + 1);
					}}
				>
					<FaAngleRight
						size={"24"}
						fill={"#fff"}
					/>
				</div>
			</div>
		</>
	);
}
