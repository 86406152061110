import { useState } from "react";
import PrimaryButton from "./PrimaryButton";
import { FcGoogle } from "react-icons/fc";
import { FaRegWindowClose } from "react-icons/fa";

import { signUpG, signInG, signUp, signIn } from "../components/dataImports";

import "../styles/css/SignInUp.css";

export default function SignInUp(props) {
	const [name, setName] = useState("");
	const [mail, setMail] = useState("");
	const [pass, setPass] = useState("");
	const [signInTab, setSignInTab] = useState(true);

	return (
		<>
			<form
				method="post"
				className="center sign-in-form flex-column padding-medium-top padding-medium-bottom padding-medium-inline"
			>
				{/* CLOSE BUTTON */}
				<button
					type="button"
					className="btn close-btn"
					onClick={props.closePopup}
				>
					<FaRegWindowClose size={20} />
				</button>

				{/* SIGN IN - SIGN UP UI */}
				<div className="sign-in-up-btns center  margin-small-bottom">
					<button
						type="button"
						className="sign-in-btn font-primary small-font"
						style={{
							background: signInTab ? "#fff" : "none",
							color: signInTab ? "#000" : "000",
							border: signInTab ? "0px solid" : "0px solid",
							fontWeight: signInTab ? "500" : "initial",
							boxShadow: signInTab
								? "0px 0px 4px #1e1e1e3b"
								: "none",
						}}
						onClick={() => setSignInTab(true)}
					>
						{signIn}
					</button>
					<button
						type="button"
						className="sign-up-btn font-primary small-font"
						style={{
							background: signInTab ? "none" : "#fff",
							color: signInTab ? "#000" : "000",
							border: signInTab ? "0px solid" : "0px solid",
							fontWeight: signInTab ? "initial" : "500",
							boxShadow: signInTab
								? "none"
								: "0px 0px 4px #1e1e1e3b",
						}}
						onClick={() => setSignInTab(false)}
					>
						{signUp}
					</button>
				</div>

				{/* NAME */}
				{!signInTab && (
					<>
						<label htmlFor="full-name"></label>
						<input
							className="margin-small-bottom font-secondary"
							type="text"
							name="full-name"
							value={name}
							onChange={(e) => setName(e.target.value)}
							placeholder="Full Name"
						/>
					</>
				)}

				{/* EMAIL */}
				<label htmlFor="e-mail"></label>
				<input
					className="margin-small-bottom font-secondary"
					type="email"
					name="e-mail"
					value={mail}
					onChange={(e) => setMail(e.target.value)}
					placeholder="E-Mail"
				/>
				{/* PASSWORD */}
				<label htmlFor="password"></label>
				<input
					className="margin-medium-bottom font-secondary"
					type="password"
					name="password"
					value={pass}
					onChange={(e) => setPass(e.target.value)}
					placeholder="Password"
				/>
				{/* SIGN IN - SIGNUP BUTTON */}
				<PrimaryButton
					btnType={"submit"}
					btnClass={"btn-secondary margin-small-bottom"}
				>
					{signInTab ? signIn : signUp}
				</PrimaryButton>
				{/* GOOGLE LOG IN BUTTON */}
				<button
					className={"g-login font-secondary center "}
					type="button"
				>
					<FcGoogle size={20} /> {signInTab ? signInG : signUpG}
				</button>
			</form>
		</>
	);
}
