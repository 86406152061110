import { nanoid } from "nanoid";
import "../styles/css/home.css";

import Navbar from "./../components/Navbar";
import Hero from "../components/Hero";
import Heading from "../components/Heading";
import EclipseInfo from "../components/EclipseInfo";
import HoneycombBanner from "../components/HoneycombBanner";
import Project from "../components/Project";
import FloatingSocial from "../components/FloatingSocial";
import Form from "../components/Form";
import Reviews from "../components/Reviews";

import {
	homeHeroHeading,
	homeHerocontent,
	homeHeroPrimaryBtn,
	homeHeroMainImg,
	homeHowHeading,
	homeHowContentArr,
	homeTechHeading,
	homeTechTypeArr,
	homeFeaturedHeading,
	homeProjectArr,
	homeHeroProjectBtn,
	reviewsHeading,
	navItemsArr,
} from "./../components/dataImports";

import {
	FaSignInAlt,
	FaWpexplorer,
	FaPeopleArrows,
	FaPencilRuler,
} from "react-icons/fa";

import "../styles/css/home.css";

const homeHowIconsArr = [
	<FaSignInAlt
		size={58}
		color={"#1e1e1e"}
	/>,
	<FaWpexplorer
		size={58}
		color={"#1e1e1e"}
	/>,
	<FaPeopleArrows
		size={58}
		color={"#1e1e1e"}
	/>,
	<FaPencilRuler
		size={58}
		color={"#1e1e1e"}
	/>,
];

export default function Home() {
	// how we work
	const homeHowContent = homeHowContentArr.map((content, index) => {
		const uniqueKey = nanoid();
		const homeHowIcon = homeHowIconsArr[index];
		return (
			<>
				<div className="center">
					<EclipseInfo
						key={uniqueKey}
						content={content}
					>
						{/* icon here */}
						{homeHowIcon}
					</EclipseInfo>
				</div>
			</>
		);
	});

	// technologies we use

	const homeTechType = homeTechTypeArr.map((type, index) => {
		const uniqueKey = nanoid();
		const orderHoneycomb = (index % 2) + 1;
		const orderHeading = index % 2 === 0 ? 2 : 1;
		const bannerHeadingClass =
			index % 2 === 0 ? "font-color-ter" : "font-color-sec";
		return (
			<>
				<HoneycombBanner
					key={uniqueKey}
					content={type.content}
					orderHoneycomb={orderHoneycomb}
					orderHeading={orderHeading}
					bannerHeadingClass={bannerHeadingClass}
					img1={type["img-1"]}
					img2={type["img-2"]}
					img3={type["img-3"]}
					img4={type["img-4"]}
					alt1={type["alt-1"]}
					alt2={type["alt-2"]}
					alt3={type["alt-3"]}
					alt4={type["alt-4"]}
				/>
			</>
		);
	});

	// featured projects

	const homeProject = homeProjectArr.map((project, index) => {
		const uniqueKey = nanoid();
		// console.log(project.content);
		const orderImg = (index % 2) + 1;
		const orderContent = index % 2 === 0 ? 2 : 1;

		if (project.tags.includes("featured")) {
			return (
				<>
					<Project
						key={uniqueKey}
						content={project["content"]}
						projectHeading={project["heading"]}
						imgPath={`..${project.img}`}
						btnContent={homeHeroProjectBtn}
						orderImg={orderImg}
						orderContent={orderContent}
					/>
					<div className="project-divider margin-small-bottom"></div>
				</>
			);
		} else {
			return null;
		}
	});

	return (
		<>
			<Navbar />
			<FloatingSocial />
			<Hero
				heading={homeHeroHeading}
				content={homeHerocontent}
				heroBtn={homeHeroPrimaryBtn}
				mainImg={homeHeroMainImg}
				btnPath={navItemsArr[2]["path"]}
			/>

			{/* HOW WE WORK  */}
			<section
				id="how-we-work"
				className="center flex-column margin-medium-bottom padding-medium-inline"
			>
				<Heading
					type={2}
					content={homeHowHeading}
				/>
				<div
					id="eclipses"
					className=" center"
				>
					{homeHowContent}
				</div>
			</section>

			{/* TECHNOLOGY WE USE  */}
			<section
				id="technology"
				className="center flex-column margin-medium-bottom padding-medium-inline"
			>
				<Heading
					type={2}
					content={homeTechHeading}
				/>
				<div
					id="tech-stack"
					className="center flex-column"
				>
					{homeTechType}
				</div>
			</section>

			{/* FEATURED PROJECTS  */}
			<section
				id="featured"
				className="center  flex-column margin-medium-bottom padding-medium-inline"
			>
				<Heading
					type={2}
					content={homeFeaturedHeading}
				/>
				<div
					id="featured-projects"
					className="center flex-column"
				>
					{homeProject}
				</div>
			</section>

			{/* CLIENT REVIEWS  */}

			<Heading
				type={2}
				content={reviewsHeading}
				class={"center font-color-primary font-bolder"}
			/>

			<Reviews />

			{/* FORMS  */}
			<section
				id="form"
				className="center flex-column margin-medium-bottom padding-medium-inline"
			>
				{/* <Heading
					type={2}
					content={homeFeaturedHeading}
				/> */}
				<div
					id=""
					className="center flex-column"
				>
					<Form />
				</div>
			</section>
		</>
	);
}
