import React, { useState, useEffect } from "react";

import "./../styles/css/root.css";
import "./../styles/css/Hero.css";

import PrimaryButton from "./PrimaryButton";
import Heading1 from "./Heading";
// import mainImg from "./../assets/images/hero-image-incomplete.svg";

export default function Hero(props) {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const handleResize = () => {
		window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
	};
	return (
		<>
			<div className="hero-container padding-medium-inline">
				<div className="hero">
					<div
						className={`hero-left`}
						// {`hero-left ${`${isMobile && "center"}  `} `}
					>
						<Heading1
							type={1}
							content={props.heading}
						></Heading1>
						{/* <h1 className="font-primary font-bolder margin-small-bottom">
							{props.heading}
						</h1> */}
						{isMobile && (
							<img
								src={props.mainImg}
								alt="DCM Hero"
								height={200}
								width={200}
								className="hero-img"
								autoplay
							/>
						)}
						<p className="body-font font-secondary margin-medium-bottom">
							{props.content}
						</p>

						<a href={props.btnPath}>
							<PrimaryButton>{props.heroBtn}</PrimaryButton>
						</a>
					</div>
					{!isMobile && (
						<div className="hero-right">
							<img
								src={props.mainImg}
								alt="DCM Hero"
								// height={400}
								// width={400}
								className="hero-img"
								autoplay
							/>
						</div>
					)}
				</div>
			</div>
		</>
	);
}
