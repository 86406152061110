import "../styles/css/Popup.css";

export default function Popup(props) {
	return props.trigger ? (
		<>
			<div className={`popup center ${props.class || ""}`}>
				<div className="popup-inner">{props.children}</div>
			</div>
		</>
	) : (
		""
	);
}
