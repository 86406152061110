import React, { useState, useEffect, useRef } from "react";
import PrimaryButton from "./PrimaryButton";
import Heading1 from "./Heading";

import "./../styles/css/root.css";
import "./../styles/css/Hero.css";

// import mainImg from "./../assets/images/hero-image-incomplete.svg";

export default function HeroDynamic({
	children,
	heading,
	content,
	heroBtn,
	orderHeroLeft,
	orderHeroRight,
	widthHeroLeft,
	height,
	classes,
}) {
	const [isMobile, setIsMobile] = useState(false);

	const handleResize = () => {
		window.innerWidth < 600 ? setIsMobile(true) : setIsMobile(false);
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const heroLeftRef = useRef(null);
	const heroRightRef = useRef(null);
	useEffect(() => {
		if (widthHeroLeft !== null || widthHeroLeft !== undefined) {
			heroLeftRef.current.style.flexBasis = widthHeroLeft;
		}
		heroLeftRef.current.style.order = orderHeroLeft;
		heroRightRef.current.style.order = orderHeroRight;
	}, [widthHeroLeft, orderHeroLeft, orderHeroRight]);
	return (
		<>
			<div
				style={{ height: `${height}` }}
				className={` hero-dynamic hero-container  ${
					classes || ""
				} padding-medium-inline`}
			>
				<div className="hero">
					<div
						ref={heroLeftRef}
						className="hero-left"
					>
						<Heading1
							type={1}
							content={heading}
						></Heading1>
						{/* <h1 className="font-primary font-bolder margin-small-bottom">
							{heading}
						</h1> */}
						{isMobile && children}
						<p className="body-font font-secondary margin-medium-bottom">
							{content}
						</p>

						{heroBtn !== undefined && heroBtn !== null && (
							<PrimaryButton>{heroBtn}</PrimaryButton>
						)}
						{/* {console.log(heroBtn)} */}
					</div>
					{!isMobile && (
						<div
							ref={heroRightRef}
							className="hero-right center"
						>
							{children}
						</div>
					)}
				</div>
			</div>
		</>
	);
}
