import { useRef, useEffect, useState } from "react";
import { FaReact, FaHtml5 } from "react-icons/fa";

import PrimaryButton from "./PrimaryButton";
import Heading from "./Heading";

import "./../styles/css/Project.css";

export default function Project(props) {
	const projectImgRef = useRef(null);
	const projectContentRef = useRef(null);
	const blackBgRef = useRef(null);

	const [isMobile, setIsMobile] = useState(false);

	// FOR MOBILE VIEW
	useEffect(() => {
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const handleResize = () => {
		window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
	};

	// CHANGE ORDER OF SECTIONS
	useEffect(() => {
		if (isMobile) {
			projectImgRef.current.style.order = "2";
			projectContentRef.current.style.order = "1";
		} else {
			projectImgRef.current.style.order = props.orderImg;
			projectContentRef.current.style.order = props.orderContent;
		}

		if (props.orderImg === 1) {
			blackBgRef.current.style.left = "0";
		} else {
			blackBgRef.current.style.left = "";
			blackBgRef.current.style.right = "0";
		}
	}, [isMobile, props.orderImg, props.orderContent]);

	return (
		<>
			<div className="project-container center margin-medium-bottom">
				<div
					ref={blackBgRef}
					className="black-bg"
				></div>

				<div className="project center">
					{/* project img */}
					<div
						ref={projectImgRef}
						className="project-item  project-img-container center"
					>
						<img
							src={props.imgPath}
							alt="DCM Projects"
							className="project-img"
						/>
					</div>

					{/* project content */}
					<div
						ref={projectContentRef}
						className="project-item project-content center flex-column"
					>
						<Heading
							type={3}
							content={props.projectHeading}
							class={"font-bold font-color-sec"}
						/>
						{/* project text */}
						<p className="font-secondary">{props.content}</p>
						{/* tech stack glimpse */}
						<div className="made-with center">
							<p className="made-with-stack center font-secondary font-small">
								<FaReact
									color={"#CF55E6"}
									size={isMobile ? "36" : "18"}
								/>
								{!isMobile && "Made with - React"}
							</p>
							<p className="made-with-stack center font-secondary">
								<FaHtml5
									color={"#CF55E6"}
									size={isMobile ? "36" : "18"}
								/>
								{!isMobile && "Made with - React"}
							</p>
						</div>
						{/* <PrimaryButton btnClass={"btn-secondary"}>
							{props.btnContent}
						</PrimaryButton> */}
					</div>
				</div>
			</div>
		</>
	);
}
