import Navbar from "../components/Navbar";
import HeroDynamic from "../components/HeroDynamic";
import Form from "../components/Form";
import FloatingSocial from "../components/FloatingSocial";

import {
	contactHeroHeading,
	contactHerocontent,
	contactLocationHeading1,
} from "../components/dataImports";

import "./../styles/css/contact.css";

import Banner from "../components/Banner";

export default function Contact() {
	const locationImg = `../assets/images/location-img.png`;
	return (
		<>
			<Navbar />
			<FloatingSocial />
			<HeroDynamic
				heading={contactHeroHeading}
				content={contactHerocontent}
				heroBtn={null}
				orderHeroLeft={"0"}
				orderHeroRight={"2"}
				widthHeroLeft={"50%"}
				height={"85vh"}
				classes={"hero-contact"}
			>
				<Form></Form>
			</HeroDynamic>
			<Banner
				contentH1={contactLocationHeading1}
				contentH2={"India"}
				imgPath={locationImg}
			/>
		</>
	);
}
