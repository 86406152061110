import Heading from "./Heading";
import "../styles/css/banner.css";

import { FaMapMarkerAlt } from "react-icons/fa";

export default function Banner(props) {
	return (
		<>
			<div className="banner center flex-column font-color-ter margin-medium-inline padding-medium-bottom padding-medium-top margin-medium-bottom">
				<Heading
					type={2}
					content={props.contentH1}
				/>
				<article className="location-container center padding-medium-inline">
					<div className="location-content center flex-column">
						<Heading
							type={3}
							content={props.contentH2}
							class={"font-bold margin-small-bottom"}
						/>
						<p className="location-text font-primary  font-regular font-small">
							<FaMapMarkerAlt /> C-127, 3rd Floor Phase 8.
							Industrial Area, Mohali, Punjab (160055), IN
						</p>
					</div>
				</article>
			</div>
		</>
	);
}
