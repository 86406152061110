import { useState } from "react";
import "react-dropdown/style.css";
import data from "../data";
import { nanoid } from "nanoid";
import "../styles/css/Form.css";
import PrimaryButton from "./PrimaryButton";

import { submitBtn } from "../components/dataImports";

const cuntCodesArray = data["9"]["country-codes"];

export default function Form() {
	const [name, setName] = useState("");
	const [mail, setMail] = useState("");
	const [cuntCode, setCuntCode] = useState(1);

	function handleName(e) {
		setName(e.target.value);
	}

	function handleMail(e) {
		setMail(e.target.value);
	}

	function handleCuntCode(e) {
		setCuntCode(e.target.value);
	}

	return (
		<>
			<form
				method="post"
				className="center contact-form flex-column padding-medium-top padding-medium-bottom padding-medium-inline"
			>
				<label
					htmlFor="full-name"
					className="visually-hidden"
				>
					Enter your full name
				</label>
				<input
					className="margin-small-bottom font-secondary"
					type="text"
					name="full=-name"
					value={name}
					onChange={handleName}
					placeholder="Full Name"
				/>
				<label
					htmlFor="e-mail"
					className="visually-hidden"
				>
					Enter your e-mail
				</label>
				<input
					className="margin-small-bottom font-secondary"
					type="email"
					name="e-mail"
					value={mail}
					onChange={handleMail}
					placeholder="E-mail"
				/>

				<div className="number-container center ">
					<label
						htmlFor="cunt-code"
						className="visually-hidden"
					>
						Select your country code
					</label>
					<select
						className="cunt-code margin-small-bottom font-secondary"
						onChange={handleCuntCode}
						name="cunt-code"
						id="cunt-code"
						value={cuntCode}
					>
						{cuntCodesArray.map((code) => {
							const uniqueKey = nanoid();
							return (
								<option
									key={uniqueKey}
									value={code}
								>
									+{code}
								</option>
							);
						})}
					</select>

					<label
						htmlFor="number"
						className="visually-hidden"
					>
						Enter your number
					</label>
					<input
						className="number margin-small-bottom font-secondary"
						type="text"
						name="number"
						placeholder="Phone Number"
					/>
				</div>

				<label
					htmlFor="message"
					className="visually-hidden"
				>
					Write your message here
				</label>
				<textarea
					className="margin-small-bottom font-secondary"
					name="message"
					placeholder="Your message here"
				></textarea>

				<PrimaryButton
					btnType={"submit"}
					btnClass={"btn-secondary font-bold"}
				>
					{submitBtn}
				</PrimaryButton>
			</form>
		</>
	);
}
