import data from "../data.json";

// NavBar
export const navItemsArr = data["0"].nav;

// Home Hero
export const homeHeroHeading = data["1"]["section-headings"]["home"]["hero"];
export const homeHerocontent = data["2"]["hero-content"]["home"];
export const homeHeroPrimaryBtn = data["7"]["buttons"]["quote"];
export const homeHeroMainImg = data["6"]["images"]["home"]["hero"]["hero-main"];
export const homeHeroTopLeft =
	data["6"]["images"]["home"]["hero"]["top-left-circle"];
export const homeHeroTopRight =
	data["6"]["images"]["home"]["hero"]["top-right-circle"];
export const homeHeroBottom =
	data["6"]["images"]["home"]["hero"]["bottom-circle"];

// HOW WE WORK
export const homeHowHeading = data["1"]["section-headings"]["home"]["how"];
export const homeHowContentArr = data["4"]["dcm-working"];

// TECHNOLOGY WE USE
export const homeTechHeading = data["1"]["section-headings"]["home"]["tech"];
export const homeTechTypeArr = data["5"]["technology"];

// FEATURED PROJECTS
export const homeFeaturedHeading =
	data["1"]["section-headings"]["home"]["featured"];
export const homeProjectArr = data["3"]["projects"];
export const homeHeroProjectBtn = data["7"]["buttons"]["project"];

// PORTFOLIO
// Hero
export const portfolioHeroHeading =
	data["1"]["section-headings"]["portfolio"]["hero"];
export const portfolioHerocontent = data["2"]["hero-content"]["portfolio"];
export const portfolioProjectBtn = data["7"]["buttons"]["portfolio"];
export const portfolioHeroImg =
	data["6"]["images"]["portfolio"]["hero"]["hero-main"];

// CONTACT
export const contactHeroHeading =
	data["1"]["section-headings"]["contact"]["hero"];
export const contactHerocontent = data["2"]["hero-content"]["contact"];
export const contactLocationHeading1 =
	data["1"]["section-headings"]["contact"]["locations"];

//FORM
export const submitBtn = data["7"]["buttons"]["submit"];

export const signIn = data["7"]["buttons"]["sign-in"];
export const signUp = data["7"]["buttons"]["sign-up"];
export const signInG = data["7"]["buttons"]["sign-in-g"];
export const signUpG = data["7"]["buttons"]["sign-up-g"];

// REVIEWS
export const reviewsHeading = data["1"]["section-headings"]["home"]["reviews"];
export const reviewsArr = data["8"]["reviews"];
